import * as React from 'react'

import { SwapVert } from '@mui/icons-material'
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { LandingCtaButton, Subtitle, Title } from 'shared/components'
import { BaseCurrencyField } from 'shared/forms'
import { isLoggedIn, numberFormatter } from 'shared/services'

import type { Theme } from '@mui/material'

const CLPUSD_PRICE = 948
const USDCLP_PRICE = 952

type EXCHANGE_TYPE = 'CLPUSD' | 'USDCLP'

const currencyFormatter = numberFormatter(2)

const ConverterSection: React.FC = () => {
  const [inputAmount, setInputAmount] = React.useState<number | undefined>(0)
  const [outputAmount, setOutputAmount] = React.useState<number | undefined>(0)
  const [exchangeType, setExchangeType] = React.useState<EXCHANGE_TYPE>('CLPUSD')

  const exchangeRate = exchangeType === 'CLPUSD' ? 1 / CLPUSD_PRICE : USDCLP_PRICE
  const inputCurrency = exchangeType === 'CLPUSD' ? 'CLP' : 'USD'
  const outputCurrency = exchangeType === 'CLPUSD' ? 'USD' : 'CLP'

  const switchExchangeType = () => {
    setExchangeType((value) => value === 'CLPUSD' ? 'USDCLP' : 'CLPUSD')
    setInputAmount(outputAmount)
  }

  React.useEffect(() => {
    if (typeof inputAmount !== 'undefined') {
      if (inputAmount > 1_000_000_000) {
        setInputAmount(1_000_000_000)
      }

      setOutputAmount(inputAmount * exchangeRate)
    }
  }, [exchangeRate, inputAmount])

  return (
    <Grid
      item
      xs={12}
      md={6}
    >
      <Box
        sx={{
          mx: 'auto',
          width: '100%',
          maxWidth: '480px',
          border: 2,
          borderColor: 'primary.main',
          borderRadius: 2,
          boxShadow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          p: 3,
        }}
      >
        <Subtitle mainTitle>
          El mejor valor del dólar hoy en Chile
        </Subtitle>
        <Box sx={{ display: 'flex', width: '100%', gap: 1, my: 2 }}>
          <Button
            variant={exchangeType === 'CLPUSD' ? 'contained' : 'outlined'}
            color='primary'
            onClick={switchExchangeType}
            sx={{ flexGrow: 1 }}
          >
            Compra: {currencyFormatter.format(CLPUSD_PRICE)}
          </Button>
          <Button
            variant={exchangeType === 'USDCLP' ? 'contained' : 'outlined'}
            color='primary'
            onClick={switchExchangeType}
            sx={{ flexGrow: 1 }}
          >
            Venta: {currencyFormatter.format(USDCLP_PRICE)}
          </Button>
        </Box>
        <FormControl fullWidth>
          <BaseCurrencyField
            label={`Cantidad en ${inputCurrency}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <small>{inputCurrency}</small>&nbsp;$
                </InputAdornment>
              ),
            }}
            onChange={setInputAmount}
            value={inputAmount}
            digits={inputCurrency === 'CLP' ? 0 : 2}
            positive
          />
        </FormControl>
        <IconButton onClick={switchExchangeType}>
          <SwapVert />
        </IconButton>
        <Box
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: 1,
            borderColor: 'secondary.main',
            borderRadius: 2,
            bgcolor: 'secondary.light',
            width: '100%',
          }}
        >
          <Typography>Recibes</Typography>
          <Typography
            variant='h4'
            component='p'
          >
            <small>{outputCurrency}</small>&nbsp;$
            {' '}
            {numberFormatter(outputCurrency === 'CLP' ? 0 : 2).format(outputAmount || 0)}
          </Typography>
        </Box>
        <LandingCtaButton
          id='hero-cta'
          size='large'
          sx={{ mt: 2, px: 4 }}
          isLoggedIn={isLoggedIn}
        />
      </Box>
    </Grid>
  )
}

const TextSection = () => (
  <Grid
    item
    xs={12}
    md={6}
    paddingBottom={3}
  >
    <Title
      mainTitle
      colorful
      fontWeight={600}
      paddingBottom={4}
      fontSize={{ xs: '3.5rem', sm: '4.5rem', md: '5rem', lg: '6rem' }}
    >
      Dólar 360
    </Title>
    <Subtitle
      mainTitle
      fontWeight={300}
    >
      Compra o vende dólares de forma rápida, segura y al mejor precio.
      Recíbelos en tu cuenta en dólares de tu banco en Chile.
    </Subtitle>
  </Grid>
)

const LandingTitle = () => (
  <Container
    maxWidth='lg'
    sx={{ py: 2 }}
  >
    <Grid
      container
      minHeight='min(85vh, 650px)'
      textAlign={useMediaQuery((theme: Theme) => theme.breakpoints.up('md')) ? 'left' : 'center'}
      justifyContent='center'
      alignItems='center'
      spacing={3}
      rowSpacing={3}
    >
      <TextSection />
      <ConverterSection />
    </Grid>
  </Container>
)

export default LandingTitle

import adrianPhoto from '../images/landing/testimonials/adrian.jpg'
import agustinPhoto from '../images/landing/testimonials/agustin.jpg'
import cristianPhoto from '../images/landing/testimonials/cristian.jpg'
import cristobalPhoto from '../images/landing/testimonials/cristobal.jpg'
import felipePhoto from '../images/landing/testimonials/felipe.jpg'
import feliperPhoto from '../images/landing/testimonials/feliper.jpg'
import franciscaPhoto from '../images/landing/testimonials/francisca.jpg'
import francisca2Photo from '../images/landing/testimonials/francisca2.jpg'
import francoPhoto from '../images/landing/testimonials/franco.jpg'
import hernanPhoto from '../images/landing/testimonials/hernan.jpg'
import ismaelPhoto from '../images/landing/testimonials/ismael.jpg'
import italoPhoto from '../images/landing/testimonials/italo.jpg'
import joseIgnacioPhoto from '../images/landing/testimonials/jose-ignacio.jpg'
import joseLuisPhoto from '../images/landing/testimonials/jose-luis.jpg'
import josePhoto from '../images/landing/testimonials/jose.jpg'
import josefinaPhoto from '../images/landing/testimonials/josefina.jpg'
import marianaPhoto from '../images/landing/testimonials/mariana.jpg'
import matiPhoto from '../images/landing/testimonials/matias.jpg'
import momoPhoto from '../images/landing/testimonials/momo.jpg'
import pamelaPhoto from '../images/landing/testimonials/pamela.jpg'
import pedroPhoto from '../images/landing/testimonials/pedro.jpg'
import sebastianPhoto from '../images/landing/testimonials/sebastian.jpg'
import valentinaPhoto from '../images/landing/testimonials/valentina.jpg'
import zazuPhoto from '../images/landing/testimonials/zazu.jpg'

import type { Testimonial } from 'shared/components'

export const testimonialsData: Testimonial[] = [
  {
    name: 'Jose',
    title: 'Marketing',
    quote: 'Me gusta lo simple y barato que fue comprar dólares.',
    imageSrc: josePhoto,
  },
  {
    name: 'José Luis',
    title: 'Ingeniero',
    quote: 'Nunca más voy a una casa de cambio, increíble!!',
    imageSrc: joseLuisPhoto,
  },
  {
    name: 'Cristóbal ',
    title: 'Noob',
    quote: 'Compré dólares en 3 pasos, muy fácil y confiable.',
    imageSrc: cristobalPhoto,
  },
  {
    name: 'Cristóbal',
    title: 'Degen',
    quote: 'Compré dólares y los pasé a mi cuenta del Santander para pagar mi tarjeta, está simple. 10/10',
    imageSrc: momoPhoto,
  },
  {
    name: 'Felipe',
    title: 'Trader',
    quote: 'Por fin una página amigable para comprar dólares para pagar a proveedores',
    imageSrc: felipePhoto,
  },
  {
    name: 'Ismael',
    title: 'Businessman',
    quote: 'Compré dólares y los transferí a mi cuenta del BCI en minutos.',
    imageSrc: ismaelPhoto,
  },
  {
    name: 'Hernán',
    title: 'Gamer',
    quote: 'Compré dólares y los mandé a mi proveedor en China.',
    imageSrc: hernanPhoto,
  },
  {
    name: 'Italo',
    title: 'Emprendedor',
    quote: 'No sabía que podía tener guardados mis proveedores, solo un click y pago.',
    imageSrc: italoPhoto,
  },
  {
    name: 'Francisca',
    title: 'Estudiante',
    quote: 'Muy fácil de entender para los nuevos usuarios.',
    imageSrc: francisca2Photo,
  },
  {
    name: 'Valentina',
    title: 'Empresaria',
    quote: 'Compré dólares y los envié a mi proveedor en China sin ningún problema. Gracias!',
    imageSrc: valentinaPhoto,
  },
  {
    name: 'Francisca',
    title: 'Periodista',
    quote: 'Una plataforma súper segura y simple de usar para poder pagar los dólares de mis vacaciones.',
    imageSrc: franciscaPhoto,
  },
  {
    name: 'Adrián',
    title: 'Empresario',
    quote: 'Llegué por recomendación de mi hermano, y me gustó mucho lo fácil.',
    imageSrc: adrianPhoto,
  },
  {
    name: 'Mariana',
    title: 'Empresaria',
    quote: 'Plataforma amigable y barata para comprar dólares.',
    imageSrc: marianaPhoto,
  },
  {
    name: 'Sebastián',
    title: 'Experto UX',
    quote: 'Me encanta, hicieron sencillo algo que los bancos hacen muy lento y caro.',
    imageSrc: sebastianPhoto,
  },
  {
    name: 'Pedro',
    title: 'Emprendedor',
    quote: 'Pago la tarjeta de crédito de mi empresa todos los meses con ustedes.',
    imageSrc: pedroPhoto,
  },
  {
    name: 'Felipe',
    title: 'Ingeniero',
    quote: 'Lo encontré muy fácil y muy seguro, gran alternativa para comprar dólares.',
    imageSrc: feliperPhoto,
  },
  {
    name: 'Agustín',
    title: 'Aussie Traveler',
    quote: 'Pude pagar mi universidad en USA. Mucho más barato que mi banco.',
    imageSrc: agustinPhoto,
  },
  {
    name: 'Josefina',
    title: 'Diseñadora',
    quote: 'Compré dólares para pagar mis vacaciones y los transferí a la cuenta en dólares sin problemas.',
    imageSrc: josefinaPhoto,
  },
  {
    name: 'Aranzazú',
    title: 'Abogada',
    quote: 'Me costó confiar en la primera compra, pero la verdad es que funciona perfect!',
    imageSrc: zazuPhoto,
  },
  {
    name: 'Matías',
    title: 'Arquitecto',
    quote: 'Gran experiencia, lo recomiendo 100%',
    imageSrc: matiPhoto,
  },
  {
    name: 'Pamela',
    title: 'Empresaria',
    quote: 'Súper fácil, parti comprando dólares en la personal. Ahora lo ocupo para mi pega.',
    imageSrc: pamelaPhoto,
  },
  {
    name: 'Cristián',
    title: 'Médico Nuclear',
    quote: 'Pagué la tarjeta de crédito de mi empresa en dólares. Mucho más barato y sin tanta vuelta.',
    imageSrc: cristianPhoto,
  },
  {
    name: 'Franco',
    title: 'Hidrógeno Verde',
    quote: 'Es simple y la plataforma es agradable visualmente.',
    imageSrc: francoPhoto,
  },
  {
    name: 'Jose Ignacio',
    title: 'Abogado',
    quote: 'Súper simple e intuitivo.',
    imageSrc: joseIgnacioPhoto,
  },
]

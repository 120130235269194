import * as React from 'react'

import { Box } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { BaseSeoSection } from 'shared/components'

const WomanSection = () => (
  <BaseSeoSection
    minHeight='min(85vh, 420px)'
    seoTitle='Todos tus bancos en un solo lugar'
    seoSubtitle={(
      'Sin importar el banco que tengas, Dólar360 te permite comprar'
      + ' con simples transferencias'
    )}
    imageWidth={4}
    imageComponent={(
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <StaticImage
          src='../../images/landing/phone.png'
          alt='Celular con Dólar360'
          placeholder='none'
          layout='fixed'
          height={420}
          quality={95}
        />
      </Box>
    )}
  />
)

export default WomanSection

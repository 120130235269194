import * as React from 'react'

import { Container, Grid, Stack, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { Title } from 'shared/components'

const StepsSection = () => (
  <Container
    sx={{
      minHeight: 'min(85vh, 420px)',
      maxWidth: 'lg',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 6,
    }}
  >
    <Title
      colorful
      textAlign='center'
    >
      ¿Cómo funciona?
    </Title>
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      spacing={4}
    >
      <Grid
        item
        xs={12}
        md={4}
      >
        <Stack alignItems='center'>
          <StaticImage
            src='../../images/landing/cotiza.png'
            alt='Cotiza tu operación'
            placeholder='blurred'
            layout='fixed'
            height={200}
            quality={90}
          />
          <Typography
            variant='h5'
            textAlign='center'
            sx={{ mt: 4 }}
          >
            Regístrate en Dólar360
          </Typography>
          <Typography
            variant='subtitle1'
            textAlign='center'
            sx={{ mt: 2 }}
          >
            Con tu correo y tu carnet de identidad regístrate en pocos pasos
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Stack alignItems='center'>
          <StaticImage
            src='../../images/landing/transfiere.png'
            alt='Transfiere a Dólar360'
            placeholder='blurred'
            layout='fixed'
            height={200}
            quality={90}
          />
          <Typography
            variant='h5'
            textAlign='center'
            sx={{ mt: 4 }}
          >
            Cotiza y paga
          </Typography>
          <Typography
            variant='subtitle1'
            textAlign='center'
            sx={{ mt: 2 }}
          >
            Cotiza el monto a cambiar y transfiere los fondos desde tu cuenta o tu tarjeta de crédito
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Stack alignItems='center'>
          <StaticImage
            src='../../images/landing/recibe.png'
            alt='Recibe tu cambio'
            placeholder='blurred'
            layout='fixed'
            height={200}
            quality={90}
          />
          <Typography
            variant='h5'
            textAlign='center'
            sx={{ mt: 4 }}
          >
            Elige donde recibir tu dinero
          </Typography>
          <Typography
            variant='subtitle1'
            textAlign='center'
            sx={{ mt: 2 }}
          >
            Selecciona donde necesitas enviar tus fondos.
            Nosotros nos encargamos del resto.
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  </Container>
)

export default StepsSection

import * as React from 'react'

import { Container, Grid } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { Title } from 'shared/components'

const LogoContainer = ({ children }: { children: React.ReactNode }) => (
  <Grid
    item
    xs={6}
    sm={3}
    md={2}
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    {children}
  </Grid>
)

const NewsSection = () => (
  <Container
    sx={{
      minHeight: 'min(85vh, 400px)',
      maxWidth: 'lg',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 6,
    }}
  >
    <Title
      colorful
      textAlign='center'
    >
      Dólar 360 en las noticias
    </Title>
    <Grid
      container
      justifyContent='space-between'
      spacing={2}
    >
      <LogoContainer>
        <StaticImage
          src='../../images/landing/df.png'
          alt='Diario Financiero'
          height={200}
        />
      </LogoContainer>
      <LogoContainer>
        <StaticImage
          src='../../images/landing/df+.png'
          alt='Diario Financiero +'
          height={200}
        />
      </LogoContainer>
      <LogoContainer>
        <StaticImage
          src='../../images/landing/mercurio.png'
          alt='El Mercurio Inversiones'
          height={200}
        />
      </LogoContainer>
      <LogoContainer>
        <StaticImage
          src='../../images/landing/lun.png'
          alt='Las Últimas Noticias'
          height={200}
        />
      </LogoContainer>
    </Grid>
  </Container>
)

export default NewsSection

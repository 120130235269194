import * as React from 'react'

import { Box, Button, Typography } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { BaseSeoSection } from 'shared/components'

const WomanSection = () => (
  <BaseSeoSection
    seoTitle='Cuenta para personas y empresas'
    seoSubtitle={(
      'Accede a la forma de comprar los dólares más baratos de Chile'
    )}
    seoBody={(
      <>
        <Typography
          variant='body1'
          gutterBottom
          sx={{ mb: 3 }}
        >
          Una aplicación rápida e intuitiva, con una interfaz tope de línea para
          que puedas acceder a la divisa más poderosa del mundo 🇺🇸
        </Typography>
        <Button
          variant='contained'
          color='primary'
          size='large'
        >
          Contactános
        </Button>
      </>
    )}

    imageComponent={(
      <Box sx={{ borderRadius: 2, overflow: 'hidden', boxShadow: 1 }}>
        <StaticImage
          src='../../images/landing/woman.png'
          alt='Mujer usando Dólar360'
          placeholder='blurred'
          layout='constrained'
          width={700}
          quality={95}
          objectFit='contain'
        />
      </Box>
    )}
  />
)

export default WomanSection

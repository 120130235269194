import * as React from 'react'

import {
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { BaseSeoSection, Table } from 'shared/components'

const CompareRatesSection = () => {
  const rates = [
    { name: 'Santander', compra: '$935', venta: '$965', highlight: false },
    { name: 'Scotiabank', compra: '$938', venta: '$962', highlight: false },
    { name: 'Itaú', compra: '$938', venta: '$962', highlight: false },
    { name: 'Banco de Chile', compra: '$940', venta: '$960', highlight: false },
    { name: 'BCI', compra: '$940', venta: '$960', highlight: false },
    { name: 'Dólar360', compra: '$948', venta: '$952', highlight: true },
  ]

  return (
    <BaseSeoSection
      reversed
      minHeight='min(85vh, 420px)'
      seoTitle='Tenemos el mejor precio del mercado'
      seoSubtitle={(
        'Compra tus dólares de forma segura, rápida y confiable al mejor precio'
      )}
      seoBody={(
        <Button
          variant='contained'
          color='primary'
          size='large'
        >
          Conocer más
        </Button>
      )}
      imageComponent={(
        <TableContainer
          component={Paper}
          sx={{ boxShadow: 1, border: 2, borderColor: 'primary.main' }}
        >
          <Table sx={{ '.MuiTableCell-head': { fontSize: '1rem' } }}>
            <TableHead>
              <TableRow>
                <TableCell>Empresa</TableCell>
                <TableCell>Compra</TableCell>
                <TableCell>Venta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rates.map((rate, index) => (
                <TableRow
                  key={index}
                  sx={{
                    bgcolor: rate.highlight ? 'secondary.light' : 'grey.50',
                    '.MuiTableCell-root': {
                      textAlign: 'center',
                      color: rate.highlight ? 'common.black' : 'text.primary',
                      fontWeight: rate.highlight ? 'bold' : 'normal',
                    },
                  }}
                >
                  <TableCell>{rate.name}</TableCell>
                  <TableCell>{rate.compra}</TableCell>
                  <TableCell>{rate.venta}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    />
  )
}

export default CompareRatesSection

import * as React from 'react'

import { AccountBalanceWallet, Share, ThumbUpAlt } from '@mui/icons-material'
import { Box, Button, Link } from '@mui/material'

import { BaseSeoSection, Subtitle } from 'shared/components'

const ReferralInstruction = ({ children }: { children: React.ReactNode }) => (
  <span
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      lineHeight: 1.3,
      paddingBottom: '1em',
    }}
  >
    {children}
  </span>
)

const ReferralsSection = () => (
  <BaseSeoSection
    seoTitle='Recomienda y Gana'
    seoSubtitle={(
      <React.Fragment>
        <ReferralInstruction>
          <Share color='primary' />
          Comparte tu código con amigos y ambos se benefician
        </ReferralInstruction>
        <ReferralInstruction>
          <ThumbUpAlt color='primary' />
          Ellos ganarán un tipo de cambio preferencial en su primera operación
        </ReferralInstruction>
        <ReferralInstruction>
          <AccountBalanceWallet color='primary' />
          En el momento que tu amigo haga su primer cambio, tú ganas $500 en créditos
        </ReferralInstruction>
      </React.Fragment>
    )}
    imageComponent={(
      <Box
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          border: 2,
          borderColor: 'primary.main',
          borderRadius: 2,
        }}
      >
        <Subtitle>
          Gana $500 por cada referido
        </Subtitle>
        <Button
          variant='contained'
          color='primary'
          size='large'
        >
          Compartir código
        </Button>
        <Link href='#'>
          Ver mis créditos
        </Link>
      </Box>
    )}
  />
)

export default ReferralsSection

import * as React from 'react'

import { WhatsApp } from '@mui/icons-material'
import { Fab } from '@mui/material'

import { SeoHeaders } from 'shared/components'
import { TestimonialsSection } from 'shared/components/testimonials'

import AllianceSection from '../components/landing/alliance_section'
import BanksSection from '../components/landing/banks_section'
import CompareRatesSection from '../components/landing/comparison_section'
import HeroSection from '../components/landing/hero_section'
import ManSection from '../components/landing/man_section'
import NewsSection from '../components/landing/news_section'
import PhoneSection from '../components/landing/phone_section'
import ReferralsSection from '../components/landing/referrals_section'
import StepsSection from '../components/landing/steps_section'
import WomanSection from '../components/landing/woman_section'
import { testimonialsData } from '../data/testimonials'
import Layout from '../layouts/landing'

const IndexPage = () => (
  <Layout>
    <SeoHeaders description='Compra y vende dólares a los mejores precios del mercado' />
    <HeroSection />
    <BanksSection />
    <AllianceSection />
    <CompareRatesSection />
    <PhoneSection />
    <StepsSection />
    <TestimonialsSection
      counterStart={1000}
      counterLimit={14000}
      testimonials={testimonialsData}
    />
    <ManSection />
    <NewsSection />
    <ReferralsSection />
    <WomanSection />
    <Fab
      href='https://wa.me/1234567890'
      target='_blank'
      rel='noopener'
      sx={{
        position: 'fixed',
        right: 16,
        bottom: 16,
        boxShadow: 1,
        bgcolor: 'secondary.main',
        color: 'common.white',
        '&:hover': {
          bgcolor: 'secondary.dark',
        },
      }}
    >
      <WhatsApp style={{ fontSize: '2rem' }} />
    </Fab>
  </Layout>
)

export default IndexPage

import * as React from 'react'

import { Box } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { BaseSeoSection } from 'shared/components'

const ManSection = () => (
  <BaseSeoSection
    minHeight='min(85vh, 420px)'
    seoTitle='Descansa tranquilo, estamos regulados'
    seoSubtitle={(
      'Con Dólar360 estás accediendo a un portal financiero'
      + ' con un cumplimiento normativo exhaustivo'
    )}
    imageComponent={(
      <Box sx={{ borderRadius: 2, overflow: 'hidden', boxShadow: 1 }}>
        <StaticImage
          src='../../images/landing/man.png'
          alt='Hombre usando Dólar360'
          placeholder='blurred'
          layout='constrained'
          width={700}
          quality={95}
          objectFit='contain'
        />
      </Box>
    )}
  />
)

export default ManSection

import * as React from 'react'

import { Box, Grid } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { BaseSeoSection, Title } from 'shared/components'

const LogoContainer = ({ children }: { children: React.ReactNode }) => (
  <Grid
    item
    xs={6}
    md={4}
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    {children}
  </Grid>
)

const BankLogos = () => {
  return (
    <Grid
      container
      columnSpacing={3}
      rowSpacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Title
          colorful
          textAlign='center'
        >
          Bancos con los que trabajamos
        </Title>
      </Grid>
      <LogoContainer>
        <StaticImage
          src='../../images/landing/bancochile.png'
          alt='Banco de Chile'
          placeholder='none'
          height={100}
          quality={95}
          objectFit='contain'
        />
      </LogoContainer>
      <LogoContainer>
        <StaticImage
          src='../../images/landing/bancoestado.png'
          alt='Banco Estado'
          placeholder='none'
          height={100}
          quality={95}
          objectFit='contain'
        />
      </LogoContainer>
      <LogoContainer>
        <StaticImage
          src='../../images/landing/bci.png'
          alt='BCI'
          placeholder='none'
          height={100}
          quality={95}
          objectFit='contain'
        />
      </LogoContainer>
      <LogoContainer>
        <StaticImage
          src='../../images/landing/scotiabank.png'
          alt='Scotiabank'
          placeholder='none'
          height={100}
          quality={95}
          objectFit='contain'
        />
      </LogoContainer>
      <LogoContainer>
        <StaticImage
          src='../../images/landing/security.png'
          alt='Banco Security'
          placeholder='none'
          height={100}
          quality={95}
          objectFit='contain'
        />
      </LogoContainer>
      <LogoContainer>
        <StaticImage
          src='../../images/landing/itau.png'
          alt='Itau'
          placeholder='none'
          height={100}
          quality={95}
          objectFit='contain'
        />
      </LogoContainer>
    </Grid>
  )
}

const BanksSection = () => (
  <Box
    sx={{
      pt: 4,
      pb: 6,
      width: '100%',
      bgcolor: '#d1e4f2',
      boxShadow: 12,
      borderTop: 1,
      borderBottom: 1,
      borderColor: 'hsl(var(--color-shadowColor) / 0.72)',
    }}
  >
    <BaseSeoSection
      minHeight='min(85vh, 210px)'
      maxWidth='lg'
      seoTitle='Horario de funcionamiento'
      seoSubtitle='Lunes a viernes de 8:30 a 15:00'
      imageComponent={<BankLogos />}
    />
  </Box>
)

export default BanksSection

import * as React from 'react'

import { Container, Grid } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { Subtitle, Title } from 'shared/components'

const AllianceSection = () => {
  const partners = [
    {
      name: 'Lunes',
      logo: (
        <StaticImage
          src='../../images/landing/lunes.png'
          alt='Descuentos Lunes'
          placeholder='blurred'
          layout='constrained'
          width={500}
          objectFit='contain'
          quality={95}
        />
      ),
    },
    {
      name: 'Martes',
      logo: (
        <StaticImage
          src='../../images/landing/martes.png'
          alt='Descuentos Martes'
          placeholder='blurred'
          layout='constrained'
          width={500}
          objectFit='contain'
          quality={95}
        />
      ),
    },
    {
      name: 'Miércoles',
      logo: (
        <StaticImage
          src='../../images/landing/miercoles.png'
          alt='Descuentos Miércoles'
          placeholder='blurred'
          layout='constrained'
          width={500}
          objectFit='contain'
          quality={95}
        />
      ),
    },
    {
      name: 'Jueves',
      logo: (
        <StaticImage
          src='../../images/landing/jueves.png'
          alt='Descuentos Jueves'
          placeholder='blurred'
          layout='constrained'
          width={500}
          objectFit='contain'
          quality={95}
        />
      ),
    },
    {
      name: 'Viernes',
      logo: (
        <StaticImage
          src='../../images/landing/viernes.png'
          alt='Descuentos Viernes'
          placeholder='blurred'
          layout='constrained'
          width={500}
          objectFit='contain'
          quality={95}
        />
      ),
    },
  ]

  return (
    <Container
      sx={{
        pt: 10,
        pb: 4,
        minHeight: 'min(85vh, 500px)',
        maxWidth: 'lg',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <Title
        colorful
        textAlign='center'
      >
        Aprovecha los días de descuento con tu banco
      </Title>
      <Subtitle
        color='text.secondary'
        fontWeight={300}
        textAlign='center'
      >
        Sé parte de un ecosistema de oportunidades en dólares, accede a la nueva era financiera.
      </Subtitle>
      <Grid
        container
        spacing={2}
        justifyContent='center'
      >
        {partners.map((partner, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={index}
          >
            {partner.logo}
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default AllianceSection
